import React, { useState, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import { visitOrRedirectTo } from 'common/javascripts/helpers';

import cn from 'classnames';

import ReCAPTCHA from 'react-google-recaptcha';

import { createGlobalStyle } from 'styled-components';

import Timer from 'client/javascripts/components/Timer';
import TopPageNotice from 'client/javascripts/components/TopPageNotice';

// import Card from '../../components/Card';
import AuthPageHeader from './components/Header';
import WelcomeStep from './components/Welcome';
import PhoneStep from './components/PhoneStep';
import BirthdayStep from './components/BirthdayStep';
import PasswordStep from './components/PasswordStep';
import OAuth from './components/OAuth';
// import RulesAndInfo from './components/RulesAndInfo';
// import Contacts from './components/Contacts';

import styles from './Auth.module.scss';

const API = {
  loginPath: '/login.json',
  afterSignInPath: '/',
  resendPasswordPath: '/players/resend_sms.json',
  programPath: '/events/program',
};

const HEADERS = (token) => (
  {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    'X-CSRF-Token': token,
  }
);

const steps = {
  welcome: 0,
  phone: 1,
  birthday: 2,
  password: 3,
};

export default function AuthScreen({
  askBirthday, token, url, contact, welcomeText,
}) {
  const GlobalStyles = createGlobalStyle`
    html {
      --admin-color-program: ${welcomeText.admin_color_program.hex};
      --admin-bg-color-program: ${welcomeText.admin_bg_color_program.hex};
      --admin-header-color-program: ${welcomeText.admin_header_color_program.hex};
      --admin-color-quest: ${welcomeText.admin_color_quest.hex};
      --admin-bg-color-quest: ${welcomeText.admin_bg_color_quest.hex};
      --admin-header-color-quest: ${welcomeText.admin_header_color_quest.hex};
      --admin-color-nav: ${welcomeText.admin_color_nav.hex};
    }
  `;
  const [currentStep, setCurrentStep] = useState(steps.welcome);
  const [notice, setNotice] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [isShowRulesAndInfo, setIsShowRulesAndInfo] = useState(true);
  const [isShowContacts, setIsShowContacts] = useState(false);
  const [resendPasswordTimeout, setResendPasswordTimeout] = useState(0);
  const [captchaVal, setCaptchaVal] = useState(null);
  const recaptchaRef = createRef();

  const playerPhone = useRef();
  // const signInCount = useRef();

  const handleChangeCaptcha = (value) => {
    setCaptchaVal(value);
  };

  const handleExpiredCaptcha = () => {
    setCaptchaVal(null);
  };

  const handlePhoneStepSubmit = async (phone) => {
    if (!captchaVal) {
      setNotice('Ты робот');
      return;
    }

    setIsLoading(true);
    playerPhone.current = phone;
    const data = {
      player: {
        phone: playerPhone.current,
      },
      'g-recaptcha-response': captchaVal,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (result.player_exists) {
        // console.log(result);
        setResendPasswordTimeout(result.timeout_to_resend_password);
        setCurrentStep(steps.password);
      } else if (!askBirthday) {
        setCurrentStep(steps.password);
        setNotice(result.notice);
      } else {
        setCurrentStep(steps.birthday);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleBirthdayStepSubmit = async (date) => {
    if (!captchaVal) {
      setNotice('Ты робот');
      return;
    }

    setIsLoading(true);
    const [day, month, year] = date.split('/');
    const data = {
      player: {
        phone: playerPhone.current,
        birthday: `${year}-${month}-${day}`,
      },
      'g-recaptcha-response': captchaVal,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      // console.log(result);
      if (result.status === 'ok') {
        setResendPasswordTimeout(result.player.timeout_to_resend_password);
        setCurrentStep(steps.password);
        setNotice(result.notice);
      } else if (result.notice.birthday) {
        setNotice(result.notice.birthday);
      } else if (result.status === 'error') {
        setNotice(result.notice);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handlePasswordStepSubmit = async (password) => {
    if (!captchaVal) {
      setNotice('Ты робот');
      return;
    }

    setNotice('');
    setIsLoading(true);
    const data = {
      player: {
        phone: playerPhone.current,
        password,
      },
      'g-recaptcha-response': captchaVal,
    };

    try {
      const response = await fetch(API.loginPath, {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (!response.ok) {
        setNotice(result.error);
        return;
      }

      if (result.player) {
        if (result.player.program_member) visitOrRedirectTo(API.programPath);
        else visitOrRedirectTo(API.afterSignInPath);

        return;
      }

      visitOrRedirectTo(API.afterSignInPath);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleResendPasswordClick = async () => {
    if (!captchaVal) {
      setNotice('Ты робот');
      return;
    }

    setNotice('');
    setIsLoading(true);
    const currentReCaptcha = recaptchaRef.current;
    const data = {
      player: { phone: playerPhone.current },
      'g-recaptcha-response': captchaVal,
    };

    try {
      const response = await fetch(API.resendPasswordPath, {
        method: 'POST',
        headers: HEADERS(token),
        body: JSON.stringify(data),
      });
      const result = await response.json();

      setIsLoading(false);

      if (!response.ok) {
        setNotice(result.error);
        return;
      }

      setResendPasswordTimeout(result.player.timeout_to_resend_password);
      setNotice(result.notice);
      setCaptchaVal(null);
      currentReCaptcha.reset();
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleChangePhoneLinkClick = () => {
    setNotice('');
    setCurrentStep(steps.phone);
    setCaptchaVal(null);
    recaptchaRef.current.reset();
  };

  const changePhoneLink = (
    <button
      className={styles.Auth__changePhone}
      onClick={handleChangePhoneLinkClick}
      type="button"
    >
      Изменить телефон
    </button>
  );

  const resendPasswordLink = (
    <button
      className={styles.Auth__resendPassword}
      onClick={handleResendPasswordClick}
      type="button"
    >
      Выслать пароль еще раз
    </button>
  );

  const resendPasswordTimeoutMessage = (
    <div className={styles.Auth__resendPasswordMessage}>
      <span>Выслать пароль повторно можно&nbsp;через&nbsp;</span>
      <Timer
        initialCounter={resendPasswordTimeout}
        onTimeEnd={() => setResendPasswordTimeout(0)}
      />
      <span>&nbsp;сек.</span>
    </div>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case steps.phone:
        return (
          <div className={styles.Auth__panelStepPhone}>
            <div className={styles.Auth__formPanel}>
              <OAuth />
            </div>
            <div className={styles.Auth__formPanel}>
              <PhoneStep
                initialPhone={playerPhone.current}
                onSubmit={handlePhoneStepSubmit}
                isLoading={isLoading}
                welcomeText={welcomeText}
              />
            </div>
          </div>
        );
      case steps.birthday:
        return (
          <BirthdayStep
            onSubmit={handleBirthdayStepSubmit}
            onRequestPhoneChange={() => setCurrentStep(steps.phone)}
            changePhoneLink={changePhoneLink}
            isLoading={isLoading}
            welcomeText={welcomeText}
          />
        );
      case steps.password:
        return (
          <PasswordStep
            passwordLength={4}
            onSubmit={handlePasswordStepSubmit}
            onRequestPhoneChange={() => setCurrentStep(steps.phone)}
            resendPasswordLink={
              resendPasswordTimeout > 0 ? resendPasswordTimeoutMessage : resendPasswordLink
            }
            changePhoneLink={changePhoneLink}
            isLoading={isLoading}
          />
        );
      default:
        return <WelcomeStep onNext={() => setCurrentStep(steps.phone)} welcomeText={welcomeText} />;
    }
  };

  // if (isShowContacts) {
  // return <Contacts contact={contact} onClose={() => setIsShowContacts(false)} />;
  // }

  return (
    <>
      <GlobalStyles />
      <form
        className={styles.Auth}
        autoComplete="off"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

        <AuthPageHeader welcomeText={welcomeText} />

        <div className={styles.Auth__card}>
          {renderCurrentStep()}

          <div
            className={cn(styles.Auth__hide, {
              [styles.Auth__show]: (currentStep === steps.phone) || (!captchaVal && (currentStep !== steps.welcome)),
            })}
          >
            <div className={styles.Auth__captcha}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.RECAPTCHA_SITE_KEY}
                stoken={process.env.RECAPTCHA_SECRET_KEY}
                onChange={handleChangeCaptcha}
                onExpired={handleExpiredCaptcha}
              />
            </div>
          </div>
        </div>

      </form>
    </>
  );
}

AuthScreen.propTypes = {
  askBirthday: PropTypes.bool,
  token: PropTypes.string,
  url: PropTypes.string,
  welcomeText: PropTypes.shape({
    text_1: PropTypes.string,
    text_2: PropTypes.string,
    text_3: PropTypes.string,
    age_restriction: PropTypes.number,
    admin_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_nav: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
};

AuthScreen.defaultProps = {
  askBirthday: true,
  token: null,
  url: null,
};
